<template>
  <div>
    <div class="flex justify-between items-center mt-3 pl-2">
      <h3 class="font-semibold text-lg
      border-b-4 border-dashblack">
        All Requests
      </h3>
      <div class="flex items-center">
        <div class="flex items-center mx-4">
          <p class="text-card text-md font-semibold pr-2">Filter by</p>
          <el-select v-model="approvalStatus" placeholder="Select filter option" @change="filterEvent" clearable>
            <el-option v-for="item in approvalStat" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr-4">
          <el-input placeholder="Search" v-model="searchQuery" @input="search"></el-input>
        </div>
      </div>
    </div>
    <div class="mt-10">
      <el-table style="width: 100%" :data="loans.allLoanRequests" v-loading="loans.loader">
        <el-table-column prop="level" label="Loan Level"></el-table-column>
        <el-table-column prop="customer_name" label="Customer"></el-table-column>
        <el-table-column label="Amount (N)">
          <template slot-scope="scope">
            <span> {{ scope.row.amount_received | currencyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Interest Rate">
          <template slot-scope="scope">
            <span>{{ scope.row.interest_rate }} %</span>
          </template>
        </el-table-column>
        <el-table-column label="Duration">
          <template slot-scope="scope">
            <span>{{ scope.row.duration > 1 ?
              scope.row.duration + ' ' + 'months' :
              scope.row.duration + ' ' + 'month' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Date Created">
          <template slot-scope="scope">
            <span>{{ scope.row.created_at | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.approval_status !== 'pending'" class="text-tablegreen">
              Approved
            </span>
            <span v-else class="text-red-600">Pending</span>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="scope">
            <div class="flex items-center">
              <span class="mx-3 cursor-pointer" @click="viewCustomer(scope.row.id)">
                <img src="@/assets/img/eye.svg" alt="">
              </span>
              <span
                class="cursor-pointer text-black border border-gray-500 rounded px-2 hover:bg-navgreen hover:text-white hover:border-none"
                @click="approveLoan(scope.row.id)">
                Approve
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-10" v-if="loans.allLoanRequests.length > 0">
        <el-pagination background @size-change="loanRequestHandleSizeChange"
          @current-change="loanRequestHandleCurrentChange" style="float: right;"
          :current-page.sync="loanRequestCurrentPage" :page-sizes="pageSizes" :page-size="loans.perPage"
          layout="total, sizes, prev, pager, next, jumper" :total="loans.loanRequestsTotal">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  data() {
    return {
      pageSizes: this.$store.state.pageSizes,
      value: '',
      input: '',
      approvalStat: [
        {
          value: 'approved',
          label: 'Approved'
        },
        {
          value: 'declined',
          label: 'Declined'
        }
      ],
    }
  },
  mounted() {
    this.getAllLoanRequests()
  },
  computed: {
    ...mapState(['loans']),
    loanRequestCurrentPage: {
      get() {
        return this.loans.loanRequestCurrentPage
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'loanRequestCurrentPage',
          with: value
        })
      }
    },
    searchQuery: {
      get() {
        return this.loans.searchQuery
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'searchQuery',
          with: value
        })
      }
    },
    approvalStatus: {
      get() {
        return this.loans.approval_status
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'approval_status',
          with: value
        })
      }
    },
    paymentStatus: {
      get() {
        return this.loans.payment_status
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'payment_status',
          with: value
        })
      }
    },
    repaymentPlan: {
      get() {
        return this.loans.repayment_plan
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'repayment_plan',
          with: value
        })
      }
    }
  },
  methods: {
    ...mapActions([
      'getAllLoanRequests',
      'loanRequestHandleSizeChange',
      'loanRequestHandleCurrentChange',
      'approveLoanRequest'
    ]),
    viewCustomer(id) {
      this.$router.push({ name: 'view-customer', params: { id: id } })
    },
    filterEvent() {
      this.$store.commit('mutate', {
        property: 'loanRequestCurrentPage',
        with: 1
      })
      this.getAllLoanRequests()
    },
    search: debounce(function () {
      this.getAllLoanRequests()
    }, 500),
    approveLoan(loan) {
      this.approveLoanRequest(loan)
        .then((res) => {
          if (res.status === 200) {
            this.$toastr.success(res.data.message)
          }
        })
        .catch((err) => {
          this.$toastr.error(err.response.data.message)
        })
    }
  }
}
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #11141A;
  color: #FFF;
}

.el-table th>.cell {
  color: #11141A;
}
</style>